import { computed, defineComponent, ref, watch } from "vue";
import { Select, Popover, Tooltip, InputNumber, Dropdown, Menu } from "ant-design-vue";
import { XCircleIcon } from "@heroicons/vue/20/solid";
import { Colors } from "@/utils/constant";
import { fmtMsg } from "vue-glcommonui";
import { TextBoxControl } from "@/locales/localeid";
import { CheckIcon } from "@heroicons/vue/24/solid";
import { ControlTextState } from "@/views/teacher-class/components/whiteboard-palette/whiteboard-palette";
import { fontFamilies, fontSizes } from "./constants";
import { CustomSelectItem } from "@/components/common/custom-select/custom-select";
export const DEFAULT_FONT_SIZE = 36;

export enum Fonts {
  DidactGothic = "Didact Gothic",
}

export default defineComponent({
  name: "TextControlComponent",
  emits: ["update-control-text-state", "close-text-control"],
  props: {
    controlTextState: {
      type: Object as () => ControlTextState,
      required: true,
    },
  },
  components: {
    Menu,
    Select,
    SelectOption: Select.Option,
    InputNumber,
    Popover,
    Tooltip,
    XCircleIcon,
    Dropdown,
    MenuItem: Menu.Item,
    CheckIcon,
  },
  setup(props, { emit }) {
    const showListColors = ref(false);
    const updateControlTextState = (obj: Partial<ControlTextState>) => emit("update-control-text-state", obj);
    const close = () => emit("close-text-control");
    const hideListColors = () => (showListColors.value = false);
    const updateFontSize = (fontSize: number) => fontSize >= 1 && updateControlTextState({ fontSize });
    const updateFontFamily = (fontFamily: string) => updateControlTextState({ fontFamily });
    const updateColor = (fill: string) => updateControlTextState({ fill });
    const selectedFont = ref(fontFamilies.find((f) => f.name === props.controlTextState.fontFamily) || fontFamilies[0]);
    const selectedSize = ref(fontSizes.find((f) => f.name === props.controlTextState.fontSize?.toString()) || fontSizes[0]);
    const handleFamilyChange = (font: CustomSelectItem) => updateFontFamily(font.name);
    const handleSizeChange = (size: CustomSelectItem) => updateFontSize(parseInt(size.name));

    watch(
      () => props.controlTextState.fontFamily,
      () => {
        if (props.controlTextState.fontFamily !== selectedFont.value.name) {
          selectedFont.value = fontFamilies.find((f) => f.name === props.controlTextState.fontFamily) || fontFamilies[0];
        }
      },
    );

    watch(
      () => props.controlTextState.fontSize,
      () => {
        if (props.controlTextState.fontSize?.toString() !== selectedSize.value.name) {
          selectedSize.value = fontSizes.find((f) => f.name === props.controlTextState.fontSize?.toString()) || fontSizes[0];
        }
      },
    );

    watch(selectedFont, (val) => {
      handleFamilyChange(val);
    });

    watch(selectedSize, (val) => {
      handleSizeChange(val);
    });

    return {
      showListColors,
      close,
      hideListColors,
      updateColor,
      fontFamilies,
      fontSizes,
      selectedFont,
      selectedSize,
      handleFamilyChange,
      colorsList: Object.values(Colors),
      updateControlTextState,
      updateFontSize,
      updateFontFamily,
      MsgColor: computed(() => fmtMsg(TextBoxControl.Color)),
      MsgFont: computed(() => fmtMsg(TextBoxControl.Font)),
      MsgFontSize: computed(() => fmtMsg(TextBoxControl.FontSize)),
      MsgClose: computed(() => fmtMsg(TextBoxControl.Close)),
    };
  },
});
