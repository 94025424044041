<template>
  <Modal
    :destroyOnClose="true"
    :visible="isOpen"
    :title="title"
    v-bind="extraProps"
    @ok="handleOk"
    @cancel="handleCancel"
    :zIndex="999"
    :closable="closeable"
    :footer="isFooterHidden ? null : undefined"
    style="top: 40px"
    :okButtonProps="{ loading: isSubmitting }"
    :okText="submitText"
  >
    <component :is="view" v-model="dataFromView" @data-updated="onDataFromViewUpdate"> </component>
  </Modal>
</template>
<script lang="ts" src="./dynamic-modal.ts"></script>
