import { BaseResponse } from "@/services";
export interface SessionStatusModel {
  teacherId: string;
  helperId: string;
}

export type GetSessionStatusResponse = SessionStatusModel | null;

export type JoinSessionAsHelperResponse = BaseResponse<{}>;
export type CancelJoinAsHelperResponse = BaseResponse<{}>;
export type TeacherAcceptHelperResponse = BaseResponse<{}>;
export type TeacherDenyHelperResponse = BaseResponse<{}>;
export type TeacherRemoveHelperResponse = BaseResponse<{}>;
export type HelperExitResponse = BaseResponse<{}>;
export type TeacherToggleHelperVideo = BaseResponse<{}>;
export type HelperRequestTeachingResponse = BaseResponse<{}>;
export enum HelperErrorCode {
  NotAvailableSession = 0,
  HelperIsAvailableInSession = 10,
  WaitingTeacherAccept = 12,
  TeacherDeniedHelper = 13,
  HelperAndTeacherAreDuplicated = 14,
  HelperIsInAnotherSession = 16
}
