import { MutationTree } from "vuex";
import { CallingState, initCallingModuleState } from "./state";
import { defineVuexName, VuexNames } from "../utils";
import { MediaUserPublishStatus, updateUserIds } from "./utils";
import { MediaDeviceError } from "@/agora/utils";
export enum CallingUserStatus {
  LEFT,
  JOINED,
}

const mutations: MutationTree<CallingState> = {
  setCallingUserIds(state: CallingState, { id, status }: { id: string; status: CallingUserStatus }) {
    const index = state.callingUserIds.findIndex((remoteId) => remoteId === id);
    if (status === CallingUserStatus.LEFT) {
      return index > -1 && state.callingUserIds.splice(index, 1);
    }
    index === -1 && state.callingUserIds.push(id);
  },
  [defineVuexName(VuexNames.CALLING.COMMITS.SET_VIDEO_USER_IDS)](
    state: CallingState,
    { id, status }: { id: string; status: MediaUserPublishStatus },
  ) {
    updateUserIds(state.videoUserIds, { id, status });
  },
  [defineVuexName(VuexNames.CALLING.COMMITS.SET_AUDIO_USER_IDS)](
    state: CallingState,
    { id, status }: { id: string; status: MediaUserPublishStatus },
  ) {
    updateUserIds(state.audioUserIds, { id, status });
  },
  setIsJoinedSession(s: CallingState, joined: boolean) {
    s.isJoinedSession = joined;
  },
  resetState(s: CallingState) {
    if (s.postLocalUserReconnectedIntervalId) {
      clearInterval(s.postLocalUserReconnectedIntervalId);
    }
    Object.assign(s, initCallingModuleState());
  },
  setEnabledAgoraNoiseCancelling(s: CallingState, enabled: boolean) {
    s.enabledAgoraNoiseCancelling = enabled;
  },
  setPostLocalUserReconnectedIntervalId(s: CallingState, intervalId: number) {
    s.postLocalUserReconnectedIntervalId = intervalId;
  },
  setCameraError(s: CallingState, error: MediaDeviceError) {
    s.cameraError = error;
  },
  setMicrophoneError(s: CallingState, error: MediaDeviceError) {
    s.microphoneError = error;
  },
  setSpeakerError(s: CallingState, error: MediaDeviceError) {
    s.speakerError = error;
  },
};

export default mutations;
