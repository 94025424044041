import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "truncate"
}


import { calColorByBackground } from "@/utils/utils";


export default /*@__PURE__*/_defineComponent({
  __name: 'name-tag',
  props: {
    backgroundColor: {},
    teamName: {},
    name: {},
    isMuted: { type: Boolean }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  const _component_MicrophoneIcon = _resolveComponent("MicrophoneIcon")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({ backgroundColor: _ctx.backgroundColor, color: _ctx.backgroundColor ? _unref(calColorByBackground)(_ctx.backgroundColor) : undefined }),
    class: "text-white text-[10px] xl:text-xs px-1.5 2xl:px-2 py-1 rounded transition flex items-center flex-nowrap max-w-[80%] space-x-0.5 2xl:space-x-1 bg-gs-green/90"
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(["flex-1 truncate", [_ctx.teamName && 'font-bold']])
    }, _toDisplayString(_ctx.name), 3),
    (_ctx.teamName)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, "| " + _toDisplayString(_ctx.teamName), 1))
      : _createCommentVNode("", true),
    (_ctx.isMuted)
      ? (_openBlock(), _createBlock(_component_MicrophoneIcon, {
          key: 1,
          "icon-class": "h-3 w-3 2xl:h-4 2xl:w-4",
          class: "flex-shrink-0"
        }))
      : _createCommentVNode("", true)
  ], 4))
}
}

})