import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, _mergeProps({
    destroyOnClose: true,
    visible: _ctx.isOpen,
    title: _ctx.title
  }, _ctx.extraProps, {
    onOk: _ctx.handleOk,
    onCancel: _ctx.handleCancel,
    zIndex: 999,
    closable: _ctx.closeable,
    footer: _ctx.isFooterHidden ? null : undefined,
    style: {"top":"40px"},
    okButtonProps: { loading: _ctx.isSubmitting },
    okText: _ctx.submitText
  }), {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.view), {
        modelValue: _ctx.dataFromView,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dataFromView) = $event)),
        onDataUpdated: _ctx.onDataFromViewUpdate
      }, null, 40, ["modelValue", "onDataUpdated"]))
    ]),
    _: 1
  }, 16, ["visible", "title", "onOk", "onCancel", "closable", "footer", "okButtonProps", "okText"]))
}