import { MediaDeviceError } from "@/agora/utils";

export interface CallingState {
  callingUserIds: string[];
  videoUserIds: string[];
  audioUserIds: string[];
  enabledAgoraNoiseCancelling: boolean;
  postLocalUserReconnectedIntervalId?: number;
  cameraError: MediaDeviceError;
  microphoneError: MediaDeviceError;
  speakerError: MediaDeviceError;
  isJoinedSession: boolean;
}

export const initCallingModuleState = () => ({
  callingUserIds: [],
  videoUserIds: [],
  audioUserIds: [],
  enabledAgoraNoiseCancelling: true,
  postLocalUserReconnectedIntervalId: undefined,
  cameraError: { isError: false, code: "" },
  microphoneError: { isError: false, code: "" },
  speakerError: { isError: false, code: "" },
  isJoinedSession: false,
});

const state: CallingState = initCallingModuleState();

export default state;
