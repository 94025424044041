import { ActionTree } from "vuex";
import { ModalState } from "./state";
import { h } from "vue";
import { fmtMsg } from "vue-glcommonui";
import { CommonLocale, IndependentLocales, SessionReplacementLocales, Survey } from "@/locales/localeid";
import { Paths } from "@/utils/paths";
import router from "@/router";
import CustomerResponseModal from "../../views/teacher-home/components/customer-response-modal/calling-quality-survey.vue";
import { vuexName, VuexNames } from "../utils";

const actions: ActionTree<ModalState, any> = {
  async warningIdpModeBeforeExecute({ commit, dispatch }, callback: () => Promise<void>) {
    commit("open", {
      view: h("div", { innerHTML: fmtMsg(IndependentLocales.StopWarning) }),
      title: fmtMsg(IndependentLocales.StopMode),
      actions: [
        {
          label: fmtMsg(CommonLocale.CommonOkButtonText),
          callback: async () => {
            commit("close");
            await dispatch(vuexName(VuexNames.TEACHER_TEACHING.DISPATCHES.TOGGLE_INDEPENDENT_MODE), null, { root: true });
            await callback();
          },
        },
      ],
    });
  },
  async confirmReplacementSession({ commit }, callback: () => Promise<void>) {
    commit("open", {
      view: h("div", { innerHTML: fmtMsg(SessionReplacementLocales.Description) }),
      title: fmtMsg(SessionReplacementLocales.Title),
      actions: [
        {
          label: fmtMsg(CommonLocale.CommonOkButtonText),
          callback: async () => {
            commit("close");
            await callback();
          },
        },
        {
          callback: async () => {
            await router.push(Paths.Home);
          },
        },
      ],
    });
  },
  showCustomerFeedbackSurvey({ commit }) {
    commit("open", {
      view: CustomerResponseModal,
      title: fmtMsg(Survey.Title),
      props: { closeable: false, isFooterHidden: true },
    });
  },
  informDeviceError({ commit }, { errorMessage, title, callback }: { errorMessage: string; title: string; callback: () => Promise<void> }) {
    commit("open", {
      view: h("div", { innerHTML: errorMessage }),
      title: title,
      actions: [
        {
          label: "Try again",
          callback: async () => {
            await callback();
          },
        },
        {
          callback: async () => {
            commit("close");
          },
        },
      ],
    });
  },
  close({ commit }) {
    commit("close");
  },
};

export default actions;
