import { computed, createVNode, defineComponent, ref } from "vue";
import { CloseOutlined, DownOutlined, ExclamationCircleOutlined, LoadingOutlined, UpOutlined } from "@ant-design/icons-vue";
import { Button, Modal } from "ant-design-vue";
import { HelperState } from "@/store/room/interface";
import { useStore } from "vuex";
import { fmtMsg } from "vue-glcommonui";
import { CommonLocale, HelperLocales, TeacherClassLocale } from "@/locales/localeid";
import { HelperService } from "@/services";
import { Logger } from "@/utils/logger";
import {
  VideoCameraIcon,
  VideoCameraSlashIcon,
  SpeakerWaveIcon,
  SpeakerXMarkIcon,
  XMarkIcon,
  Cog6ToothIcon,
  EyeIcon,
  EyeSlashIcon,
  ArrowsPointingInIcon,
  WifiIcon,
} from "@heroicons/vue/20/solid";
import { generateAvatar } from "@/utils/utils";
import NameTag from "@/components/common/name-tag/name-tag.vue";
import { useDevicesLocking } from "@/hooks/use-devices-locking";
import { isUserPublishedAudio, isUserPublishedVideo } from "@/store/calling/utils";
import { vuexName, VuexNames } from "@/store/utils";
import { DeviceErrorTooltip } from "@/components/common/device-settings/components";
import { MediaDeviceError } from "@/agora/utils";

export default defineComponent({
  name: "TeacherClassHelperCard",
  props: {
    isMinimized: Boolean,
    onClickMinimized: {
      type: Object as () => void,
      required: true,
    },
  },
  components: {
    DeviceErrorTooltip,
    LoadingOutlined,
    NameTag,
    CloseOutlined,
    DownOutlined,
    UpOutlined,
    Button,
    VideoCameraIcon,
    VideoCameraSlashIcon,
    SpeakerWaveIcon,
    SpeakerXMarkIcon,
    XMarkIcon,
    Cog6ToothIcon,
    EyeIcon,
    EyeSlashIcon,
    ArrowsPointingInIcon,
    WifiIcon,
  },
  setup() {
    const { getters, dispatch } = useStore();
    const { isCameraLocking, isMicrophoneLocking } = useDevicesLocking(false);
    const isDeviceSettingsModalShown = ref(false);
    const helperInfo = computed<HelperState | undefined>(() => getters["teacherRoom/helperInfo"]);
    const isAudioPublished = computed<boolean>(() => isUserPublishedAudio({ userId: helperInfo.value?.id ?? "", gettersOrRootGetters: getters }));
    const isVideoPublished = computed<boolean>(() => isUserPublishedVideo({ userId: helperInfo.value?.id ?? "", gettersOrRootGetters: getters }));
    const isTeacherAllowStudentSeeHelperVideo = computed<boolean>(
      () => getters[vuexName(VuexNames.TEACHER_ROOM.GETTERS.GET_IS_TEACHER_ALLOW_STUDENT_SEE_HELPER_VIDEO)],
    );
    const helperId = computed(() => getters["teacherRoom/helperId"]);
    const isHelper = computed<boolean>(() => getters["teacher/isHelper"]);
    const oneAndOne = computed(() => getters["teacherRoom/getStudentModeOneId"]);
    const anotherTeacherIsInOneToOneMode = computed(() => !!getters["teacherRoom/getStudentModeOneWithAnotherTeacherId"]);
    const cameraError = computed<MediaDeviceError>(() => getters["calling/getCameraError"]);
    const microphoneError = computed<MediaDeviceError>(() => getters["calling/getMicrophoneError"]);
    const onHelperToggleCamera = async () => {
      let isOff = false;
      if (isVideoPublished.value) {
        isOff = true;
      }
      await dispatch("teacherRoom/toggleHelperCamera", isOff);
    };
    const onHelperToggleMicro = async () => {
      let isOff = false;
      if (isAudioPublished.value) {
        isOff = true;
      }
      await dispatch("teacherRoom/toggleHelperMicro", isOff);
    };
    const updateCamOpen = async (isOpen: boolean) => {
      await dispatch("teacherRoom/toggleHelperCamera", !isOpen);
    };

    const updateMicOpen = async (isOpen: boolean) => {
      await dispatch("teacherRoom/toggleHelperMicro", !isOpen);
    };

    const openDeviceSettingsModal = () => {
      isDeviceSettingsModalShown.value = true;
    };

    const onRemoveHelper = () => {
      Modal.confirm({
        icon: createVNode(ExclamationCircleOutlined),
        content: ConfirmRemoveHelperText.value,
        okText: CommonYesText.value,
        cancelText: CommonNoText.value,
        onOk: () => {
          dispatch("teacherRoom/teacherRemoveHelper");
        },
      });
    };

    const showHelperVideoText = computed(() => fmtMsg(HelperLocales.ShowVideoButton));
    const hideHelperVideoText = computed(() => fmtMsg(HelperLocales.HideVideoButton));
    const toggleHelperVideoLoading = ref(false);
    const toggleHelperVideo = async (isShown = false) => {
      if (isTeacherAllowStudentSeeHelperVideo.value === isShown || toggleHelperVideoLoading.value) return;
      try {
        toggleHelperVideoLoading.value = true;
        await HelperService.teacherToggleHelperVideo(isShown);
      } catch (error) {
        Logger.error(error);
      }
      toggleHelperVideoLoading.value = false;
    };

    // TODO PRE-477: In action MANAGE_REMOTE_USER_VIDEO_AND_AUDIO_SUBSCRIPTIONS, set a new state which save all ids of video which are showing on screen, because showwing video is different with published video
    const isAvatarShown = computed<boolean>(() => {
      return isHelper.value
        ? isVideoPublished.value
        : isVideoPublished.value && isTeacherAllowStudentSeeHelperVideo.value && !oneAndOne.value && !anotherTeacherIsInOneToOneMode.value;
    });

    const helperAvatar = computed(() => generateAvatar(getters["teacherRoom/helperAvatar"]));

    const CommonYesText = computed(() => fmtMsg(CommonLocale.CommonYesButtonText));
    const CommonNoText = computed(() => fmtMsg(CommonLocale.CommonNoButtonText));
    const ConfirmRemoveHelperText = computed(() => fmtMsg(TeacherClassLocale.ConfirmRemoveHelper));

    return {
      helperInfo,
      isTeacherAllowStudentSeeHelperVideo,
      isHelper,
      isVideoPublished,
      isAudioPublished,
      onHelperToggleCamera,
      onHelperToggleMicro,
      updateCamOpen,
      updateMicOpen,
      openDeviceSettingsModal,
      isDeviceSettingsModalShown,
      onRemoveHelper,
      toggleHelperVideoLoading,
      toggleHelperVideo,
      showHelperVideoText,
      hideHelperVideoText,
      isAvatarShown,
      helperAvatar,
      helperId,
      VideoCameraIcon,
      VideoCameraSlashIcon,
      SpeakerWaveIcon,
      SpeakerXMarkIcon,
      EyeIcon,
      EyeSlashIcon,
      isCameraLocking,
      isMicrophoneLocking,
      cameraError,
      microphoneError,
    };
  },
});
