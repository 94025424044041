import { ActionContext } from "vuex";
import { notification } from "ant-design-vue";
import { fmtMsg } from "vue-glcommonui";
import { lowBandWidthPoint, NetworkQualityPayload, SHOW_LOW_BANDWIDTH_NOTIFICATION_DURATION } from "@/store/room/interface";
import { StudentRoomState } from "@/store/room/student/state";
import { LowBandWidthLocales, StoreLocale } from "@/locales/localeid";
import { MainRoles, RemoteTeachingService } from "@/services";
import { CallingUserStatus } from "@/store/calling/mutations";
import { ConnectionDisconnectedReason, ConnectionState } from "agora-rtc-sdk-ng";
import { Logger } from "@/utils/logger";
import router from "@/router";
import { Paths } from "@/utils/paths";
import { isSameUserJoined, needTriggerManualReconnect } from "@/utils/utils";
import { notifySameUserJoined } from "@/utils/notifications";
import { MediaUserPublishStatus } from "@/store/calling/utils";
import { vuexName, VuexNames } from "@/store/utils";

export const callingUtils = ({ commit, dispatch, state, rootState }: ActionContext<StudentRoomState, any>): any => {
  return {
    remoteUserJoined: async (id: string) => {
      await dispatch("handleRemoteUserJoined", id);
    },
    remoteUserLeft: async (id: string, reason?: string) => {
      const { helper, teacher } = state;
      Logger.log("USER_LEFT", id, reason);
      commit("calling/setCallingUserIds", { id, status: CallingUserStatus.LEFT }, { root: true });
      if (state.isSessionEnded || !state.info?.id || !state.student?.id) return;
      let remoteUserRole = MainRoles.TEACHER;
      if (helper?.id === id) {
        remoteUserRole = MainRoles.HELPER;
      } else if (teacher?.id === id) {
        commit("setTeacherDisconnected", true);
        notification.warn({
          message: fmtMsg(StoreLocale.WaitYourTeacher),
        });
      } else {
        remoteUserRole = MainRoles.STUDENT;
      }
      //** Informing to the SERVER that user is disconnected
      RemoteTeachingService.putRemoteUserDisconnected({
        remoteUserRole: remoteUserRole,
        remoteUserId: id,
        sessionId: state.info.id,
        localUserId: state.student.id,
      });
    },
    localUserConnectionChanged: async (curState: ConnectionState, prevState: ConnectionState, reason: ConnectionDisconnectedReason | undefined) => {
      Logger.log("AGORA: Connection Changed", { curState, prevState }, reason);
      if (isSameUserJoined(curState, reason)) {
        notifySameUserJoined();
        return await router.push(Paths.Parent);
      }
      const sessionId = state.info?.id;
      const localStudentId = state.student?.id;
      if (!localStudentId || !sessionId) return;
      commit(
        "calling/setCallingUserIds",
        { id: localStudentId, status: curState === "CONNECTED" ? CallingUserStatus.JOINED : CallingUserStatus.LEFT },
        { root: true },
      );
      await dispatch(`${curState === "CONNECTED" || curState === "CONNECTING" ? "setOnline" : "setOffline"}`);
      curState === "CONNECTED" &&
        (await RemoteTeachingService.postLocalUserReconnected({
          localUserRole: MainRoles.STUDENT,
          localUserId: localStudentId,
          sessionId,
        }));
      // Manually reconnecting the Agora Room if reconnecting mechanism is not working
      if (needTriggerManualReconnect(curState)) {
        if (state.manager) {
          await state.manager.agoraClient?.leaveChannel();
          await state.manager.manualReconnectAgoraRoom();
        }
      }
    },
    handleException: async () => {
      //
    },
    bandwidthUpdateBasedOnAgora: async (payload: NetworkQualityPayload) => {
      const { uplinkNetworkQuality, downlinkNetworkQuality } = payload;
      if (uplinkNetworkQuality < lowBandWidthPoint || downlinkNetworkQuality < lowBandWidthPoint) {
        rootState.app.isLowBandWidth && (await dispatch("setLowBandWidth", false, { root: true }));
      } else if (!rootState.app.isLowBandWidth) {
        const studentId = state.student?.id;
        if (studentId) {
          await dispatch("setLowBandWidth", true, { root: true });
          notification.warning({
            key: studentId,
            message: fmtMsg(LowBandWidthLocales.LocalUserLowBandWidth),
            duration: SHOW_LOW_BANDWIDTH_NOTIFICATION_DURATION,
          });
        }
      }
    },
    handleUserPublishedVideo: async ({ id, status }: { id: string; status: MediaUserPublishStatus }) => {
      commit(vuexName(VuexNames.CALLING.COMMITS.SET_VIDEO_USER_IDS), { id, status }, { root: true });
    },
    handleUserPublishedAudio: async ({ id, status }: { id: string; status: MediaUserPublishStatus }) => {
      commit(vuexName(VuexNames.CALLING.COMMITS.SET_AUDIO_USER_IDS), { id, status }, { root: true });
    },
  };
};
