import IconImage from "@/assets/images/image.png";
import PhotoCamera from "@/assets/images/photo-camera.png";
import IconPaletteOff from "@/assets/teacher-class/touch-off-small-blue.svg";
import IconPaletteOn from "@/assets/teacher-class/touch-on-small-blue.svg";
import { StudentState } from "@/store/room/interface";
import { defineComponent } from "@vue/runtime-core";
import { gsap } from "gsap";
import { computed, inject, ref } from "vue";
import { MatIcon } from "vue-glcommonui";
import { useStore } from "vuex";
import { LoadingOutlined } from "@ant-design/icons-vue";
import { VideoCameraIcon, VideoCameraSlashIcon, QuestionMarkCircleIcon, ArrowsPointingOutIcon, ArrowsPointingInIcon } from "@heroicons/vue/20/solid";
import { useSendWebSocketMsg } from "@/hooks/use-send-websocket-msg";
import { MediaDeviceTypes } from "@/utils/utils";
import { RoomStore, useRoomStore } from "@/hooks/use-room-store";
import { isUserPublishedAudio, isUserPublishedVideo } from "@/store/calling/utils";

export default defineComponent({
  components: {
    MatIcon,
    QuestionMarkCircleIcon,
    LoadingOutlined,
  },
  props: {
    student: { type: Object as () => StudentState, required: true },
    show: Boolean,
    allowExpend: Boolean,
    isExpended: Boolean,
    focusedStudent: Boolean,
    focusDisabled: Boolean,
  },
  setup(props) {
    const isLoadingCamera = ref(false);
    const isLoadingMicrophone = ref(false);
    const isLoadingPalette = ref(false);
    const isLoadingBadge = ref(false);
    const { requestToggleStudentMediaDevices, requestToggleStudentPalette, requestSetStudentsBadge, requestClearStudentRaisingHand } =
      useSendWebSocketMsg();
    const { currentOnlineEnabledPaletteStudent } = useRoomStore(RoomStore.Teacher);
    const store = useStore();
    const paletteIcon = computed(() => (props.student.isPalette ? IconPaletteOn : IconPaletteOff));
    const isRaisingHand = computed(() => props.student.raisingHand);
    const isShowExpandIcon = computed(() => store.getters["teacherRoom/getStudentModeOneId"] !== props.student.id);
    const currentSchoolId = computed(() => store.getters["teacher/currentSchoolId"]);
    const isPaletteDisabled = computed(
      () => currentOnlineEnabledPaletteStudent.value && currentOnlineEnabledPaletteStudent.value.id !== props.student.id,
    );
    const isAudioPublished = computed<boolean>(() => isUserPublishedAudio({ userId: props.student.id, gettersOrRootGetters: store.getters }));
    const isVideoPublished = computed<boolean>(() => isUserPublishedVideo({ userId: props.student.id, gettersOrRootGetters: store.getters }));
    const onClickToggleAudio = async () => {
      isLoadingMicrophone.value = true;
      await requestToggleStudentMediaDevices({
        mediaDeviceType: MediaDeviceTypes.Microphone,
        isMute: isAudioPublished.value,
        studentIds: [props.student.id],
      });
      isLoadingMicrophone.value = false;
    };

    const onClickToggleVideo = async () => {
      isLoadingCamera.value = true;
      await requestToggleStudentMediaDevices({
        mediaDeviceType: MediaDeviceTypes.Camera,
        isMute: isVideoPublished.value,
        studentIds: [props.student.id],
      });
      isLoadingCamera.value = false;
    };

    const onClickTogglePalette = async () => {
      if (isPaletteDisabled.value) {
        return;
      }
      isLoadingPalette.value = true;
      await requestToggleStudentPalette({
        studentId: props.student.id,
        isEnable: !props.student.isPalette,
        isHelper: store.getters["teacher/isHelper"],
        isOneOne: store.getters["teacherRoom/getStudentModeOneId"] === props.student.id,
      });
      isLoadingPalette.value = false;
    };

    const onClickClearRaisingHand = async () => {
      await requestClearStudentRaisingHand(props.student.id);
    };

    const onClickAddBadge = async () => {
      isLoadingBadge.value = true;
      await requestSetStudentsBadge([props.student.id]);
      isLoadingBadge.value = false;
    };
    const toolEnter = (element: HTMLElement) => {
      gsap.from(element.children[0], {
        translateX: 0,
        translateY: 0,
        opacity: 0,
        clearProps: "all",
        ease: "Power2.easeInOut",
      });
    };

    const updateFocusStudent: any = inject("updateFocusStudent");
    const handleExpand = () => {
      if (props.focusedStudent) {
        return updateFocusStudent();
      }
      updateFocusStudent(props.student.id);
    };

    return {
      isRaisingHand,
      paletteIcon,
      onClickClearRaisingHand,
      onClickToggleAudio,
      onClickToggleVideo,
      onClickTogglePalette,
      onClickAddBadge,
      toolEnter,
      handleExpand,
      isShowExpandIcon,
      isPaletteDisabled,
      IconImage,
      PhotoCamera,
      currentSchoolId,
      VideoCameraIcon,
      VideoCameraSlashIcon,
      ArrowsPointingOutIcon,
      ArrowsPointingInIcon,
      isLoadingMicrophone,
      isLoadingCamera,
      isLoadingBadge,
      isLoadingPalette,
      isAudioPublished,
      isVideoPublished,
    };
  },
});
