<template>
  <div class="relative text-slate-600 rounded-xl w-full overflow-hidden group">
    <div class="aspect-w-16 aspect-h-9 bg-white">
      <figure>
        <div class="absolute inset-0 flex justify-center items-center">
          <ConnectAvatar v-if="isAvatarShown" :src="avatarTeacher" :alt="teacher?.name" />
          <svg
            v-show="spinnerVisible"
            v-else
            class="animate-spin h-6 w-6 text-slate-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
        <div v-show="isVideoPublished" :class="['w-full h-full object-cover', !isTeacherCalling && 'hidden']" :id="teacher?.id" ref="videoRef" />
      </figure>
    </div>
    <div
      :class="[isVideoPublished ? 'opacity-0' : '']"
      class="absolute top-2 left-1/2 -translate-x-1/2 flex space-x-1.5 group-hover:opacity-100 transition-opacity"
    >
      <template v-if="!currentUserIsHelper && isJoinedSession">
        <div class="relative">
          <DeviceErrorTooltip
            class="absolute right-[-30%] top-[-30%]"
            v-if="cameraError.isError"
            :message="cameraError.code"
            :altText="'Camera error info'"
          />
          <button
            @click="cameraError.isError ? openDeviceSettingsModal() : toggleVideo()"
            :disabled="isCameraLocking"
            class="h-7 w-7 bg-slate-100/80 flex items-center justify-center rounded-md hover:bg-white hover:shadow transition-colors cursor-pointer disabled:cursor-wait"
          >
            <LoadingOutlined class="animate-spin" v-if="isCameraLocking" />
            <component v-else :is="isVideoPublished ? VideoCameraIcon : VideoCameraSlashIcon" class="h-4 w-4" />
          </button>
        </div>
        <div class="relative">
          <DeviceErrorTooltip
            class="absolute right-[-30%] top-[-30%]"
            v-if="microphoneError.isError"
            :message="microphoneError.code"
            :altText="'Microphone error info'"
          />
          <button
            @click="microphoneError.isError ? openDeviceSettingsModal() : toggleAudio()"
            :disabled="isMicrophoneLocking"
            class="h-7 w-7 bg-slate-100/80 flex items-center justify-center rounded-md hover:bg-white hover:shadow transition-colors cursor-pointer disabled:cursor-wait"
          >
            <LoadingOutlined class="animate-spin" v-if="isMicrophoneLocking" />
            <MicrophoneIcon v-else icon-class="h-4 w-4" :enabled="isAudioPublished" />
          </button>
        </div>
        <button
          @click="openDeviceSettingsModal"
          class="h-7 w-7 bg-slate-100/80 flex items-center justify-center rounded-md hover:bg-white hover:shadow transition-colors cursor-pointer"
        >
          <Cog6ToothIcon class="h-4 w-4" />
        </button>
        <DeviceSettingsModal
          v-model:visible="isDeviceSettingsModalShown"
          :isMicEnabled="isAudioPublished"
          :isCamEnabled="isVideoPublished"
          :onUpdateCamOpen="updateCamOpen"
          :onUpdateMicOpen="updateMicOpen"
        />
      </template>
      <div
        @click="onClickMinimized"
        class="h-7 w-7 bg-slate-100/80 flex items-center justify-center rounded-md hover:bg-white hover:shadow transition-colors cursor-pointer"
      >
        <ArrowsPointingInIcon class="h-4 w-4" />
      </div>
      <template v-if="currentUserIsHelper && isInOneToOneMode && !anotherTeacherIsInOneToOneMode">
        <div
          @click="toggleOneToOneWithHelperIgnoreTeacherVoice"
          class="h-7 w-7 bg-slate-100/80 flex items-center justify-center rounded-md hover:bg-white hover:shadow transition-colors cursor-pointer"
        >
          <component :is="isOneToOneWithHelperIgnoreTeacherVoice ? SpeakerXMarkIcon : SpeakerWaveIcon" class="h-4 w-4" />
        </div>
      </template>
    </div>
    <div v-if="isLowBandWidth" class="absolute bottom-2 right-3">
      <WifiIcon class="animate-pulse h-8 w-8 text-red-600" />
    </div>
    <NameTag :name="teacher?.name" :is-muted="!isAudioPublished" class="absolute bottom-2 left-3" />
  </div>
</template>
<!--<style lang="scss" scoped src="./teacher-card.scss"></style>-->
<script lang="ts" src="./teacher-card.ts"></script>
