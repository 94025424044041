import { DynamicKeysObject } from "@/utils/type";

export type ModalAction = {
  label?: string;
  callback: (props?: any) => void;
};
export interface ModalState {
  isOpen: boolean;
  title: string;
  view: object;
  actions?: ModalAction[];
  props: DynamicKeysObject;
  isSubmitting: boolean;
}
export const initState = () => ({
  isOpen: false,
  title: "",
  view: {},
  actions: [],
  props: {},
  isSubmitting: false,
});
const state: ModalState = initState();

export default state;
