<template>
  <div class="text-base font-medium">{{ RemoteSetUpText }}</div>
  <div class="mt-6 flex flex-col items-stretch space-y-4">
    <div class="flex items-center space-x-4">
      <div class="flex flex-1 justify-between items-center">
        <div class="flex flex-1 space-x-1.5 items-center">
          <SpeakerWaveIcon class="w-6 h-6" />
          <div>{{ CheckSpeaker }}</div>
          <DeviceErrorTooltip v-if="!havePermissionSpeaker" :message="MsgSpeakerErrorInfo" :altText="'Speaker error info'" />
        </div>
        <div class="relative">
          <component :is="isPlayingSound ? PauseCircleIcon : PlayCircleIcon" class="w-10 h-10 text-gs-purple cursor-pointer" @click="toggleSpeaker" />
          <img
            v-if="isPlayingSound"
            src="@/assets/images/audio-wave.gif"
            class="h-4 w-4 absolute bottom-2 right-2 translate-x-full translate-y-full"
            alt=""
          />
        </div>
        <audio loop id="audio" class="hidden" :onplay="onSoundPlay" :onpause="onSoundPause">
          <source src="@/assets/audio/ConnectTestSound.mp3" type="audio/mp3" />
        </audio>
      </div>
      <Select
        :placeholder="SystemDefaultSpeakerDevice"
        style="width: 600px"
        :disabled="speakerError.isError"
        v-model:value="activeSpeakerId"
        ref="select"
        @change="onSpeakerChange"
      >
        <SelectOption v-for="speaker in listSpeakers" :key="speaker.deviceId" :value="speaker.deviceId">
          {{ speaker.label }}
          {{ speaker.isDefault ? `(${MsgDefaultDevice})` : "" }}
        </SelectOption>
      </Select>
    </div>
    <div class="text-red-600 pb-2" v-if="!havePermissionSpeaker">{{ warningMsgSpeaker }}</div>
    <div class="flex items-center space-x-4">
      <div class="flex flex-1 justify-between items-center">
        <div class="flex flex-1 space-x-1.5 items-center">
          <MicrophoneIcon class="w-6 h-6" />
          <div>{{ CheckMic }}</div>
          <DeviceErrorTooltip v-if="microphoneError.isError" :message="microphoneError.code" :altText="'Micro error info'" />
        </div>
        <div class="relative">
          <Switch :disabled="isSettingUpMicrophone || microphoneError.isError" v-model:checked="isMicrophoneEnabled" />
          <div @click="onAttemptOpenErrorMicrophone" class="absolute inset-0 z-10 cursor-pointer" v-if="microphoneError.isError"></div>
        </div>
      </div>
      <Select :placeholder="SelectDevice" style="width: 600px" v-model:value="activeMicrophoneId" ref="select" @change="onMicrophoneChange">
        <SelectOption v-for="mic in listMics" :key="mic.deviceId" :value="mic.deviceId">
          {{ mic.label }}
          {{ mic.isDefault ? `(${MsgDefaultDevice})` : "" }}
        </SelectOption>
      </Select>
    </div>
    <div class="flex justify-end" v-if="listMics.length > 0">
      <div class="w-[600px]">
        <Progress :strokeWidth="24" :percent="!isMicrophoneEnabled ? 0 : volumeByPercent" strokeColor="#2f0b57" :show-info="false" class="progress" />
      </div>
    </div>
    <div class="text-red-600 pb-2" v-if="!havePermissionMicrophone">{{ warningMsgMicrophone }}</div>
    <div class="flex items-center space-x-4" v-if="showCameraOptions">
      <div class="flex flex-1 justify-between items-center">
        <div class="flex flex-1 space-x-1.5 items-center">
          <VideoCameraIcon class="w-6 h-6" />
          <div>{{ CheckCam }}</div>
          <DeviceErrorTooltip v-if="cameraError.isError" :message="cameraError.code" :altText="'Camera error info'" />
        </div>
        <div class="relative">
          <Switch :disabled="isSettingUpCamera || cameraError.isError" v-model:checked="isCameraEnabled" />
          <div @click="onAttemptOpenErrorCamera" class="absolute inset-0 z-10 cursor-pointer" v-if="cameraError.isError"></div>
        </div>
      </div>
      <Select :placeholder="SelectDevice" style="width: 600px" v-model:value="activeCameraId" ref="select" @change="onCameraChange">
        <SelectOption v-for="cam in listCams" :key="cam.deviceId" :value="cam.deviceId">
          {{ cam.label }}
        </SelectOption>
      </Select>
    </div>
    <!--    <div class="text-red-600 pb-2" v-if="showCameraOptions && !havePermissionCamera">{{ warningMsgCamera }}</div>-->
    <!--    <div class="text-red-600" v-if="cameraError.isError">{{ cameraError.code }}</div>-->
    <div class="flex justify-end" v-if="showCameraOptions">
      <div class="w-[600px]">
        <div class="w-full aspect-w-16 aspect-h-9">
          <div
            :ref="
              (el) => {
                playerRef = el;
              }
            "
            :id="videoElementId"
            v-show="isCameraEnabled && currentCam && !agoraCamError"
            class="rounded-lg overflow-hidden w-full h-full object-cover"
          ></div>
          <div v-show="!isCameraEnabled || isCurrentCamError || !currentCam" class="rounded-lg overflow-hidden w-full h-full object-cover">
            <div class="relative w-full h-full flex flex-col justify-center items-center bg-slate-900" v-if="isCurrentCamError || !isCameraEnabled">
              <div class="device-tester__camera--player__notify">
                <div class="device-tester__camera--player__icon">
                  <VideoCameraSlashIcon class="w-8 h-8 text-white" />
                </div>
                <div class="device-tester__camera--player__text text-center px-10">
                  {{ isCurrentCamError && isCameraEnabled ? MsgCameraErrorInfo : CamOff }}
                </div>
              </div>
            </div>
            <VideoPlaceholder v-else style="z-index: 1" />
          </div>
        </div>
      </div>
    </div>
    <slot />
  </div>
</template>
<style lang="scss" scoped src="./device-settings.scss"></style>
<script lang="ts" src="./device-settings.ts" />
