import { DeviceType } from "@/components/common/device-settings/device-settings";
import { Logger } from "@/utils/logger";
import { isVirtualMediaDevice } from "@/utils/utils";

export interface MediaDeviceError {
  isError: boolean;
  code: string;
}

// Custom error handling function for AgoraRTC
export const mappingAgoraError = (error: any) => {
  Logger.error(error)
  if (!error || typeof error !== "object") {
    return { isError: true, code: "Unknown error" };
  }

  const errorMessages: Record<string, string> = {
    NOT_READABLE: "Camera is in use. Another application may be using the camera. Please close any apps that might be using it, refresh the page, and try again. If the issue persists, restart your device.",
    PERMISSION_DENIED: "Unable to access the camera. Your browser has blocked camera access. Please enable it in browser settings, refresh the page, and try again.",
    DEVICE_NOT_FOUND: "No devices were found.",
    UNEXPECTED_ERROR: "An unexpected error occurred while accessing the camera.",
  };

  if (error.message && error.message.includes("NotReadableError: Device in use")) {
    return { isError: true, code: "Another app is using your camera. Close the other app and try again." };
  }

  const code = errorMessages[error.code] || error.message || "An unknown AgoraRTC error occurred.";
  return { isError: true, code };
};

export function filterAndSortMicrophones(microphones: MediaDeviceInfo[]): DeviceType[] {
  const defaultMicrophone = microphones.find(microphone => microphone.deviceId === 'default');
  const defaultGroupId = defaultMicrophone ? defaultMicrophone.groupId : null;

  return microphones
    .filter(microphone =>
      microphone.deviceId !== 'communications' && (microphone.deviceId === 'default' || microphone.groupId !== defaultGroupId) && !isVirtualMediaDevice(microphone.label)
    )
    .map(microphone => ({
      deviceId: microphone.deviceId,
      label: microphone.label,
      kind: microphone.kind,
      groupId: microphone.groupId || "",
      isDefault: microphone.deviceId === "default",
    }));
}

export function filterAndSortSpeakers(speakers: MediaDeviceInfo[]): DeviceType[] {
  const defaultSpeaker = speakers.find(speaker => speaker.deviceId === 'default');
  const defaultGroupId = defaultSpeaker ? defaultSpeaker.groupId : null;

  return speakers
    .filter(speaker =>
      speaker.deviceId !== 'communications' && (speaker.deviceId === 'default' || speaker.groupId !== defaultGroupId) && !isVirtualMediaDevice(speaker.label)
    )
    .map(speaker => ({
      deviceId: speaker.deviceId,
      label: speaker.label,
      kind: speaker.kind,
      groupId: speaker.groupId || "",
      isDefault: speaker.deviceId === "default",
    }));
}
