import { Button, Modal, Row, Space } from "ant-design-vue";
import { CheckOutlined } from "@ant-design/icons-vue";
import { computed, defineComponent, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import { fmtMsg } from "vue-glcommonui";
import { ClassSetUp, CommonLocale } from "@/locales/localeid";
import { useClientSide } from "@/hooks/use-client-side";
import DeviceSettings from "@/components/common/device-settings/device-settings";

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    isStudentSide: {
      type: Boolean,
      required: false,
    },
    isCamEnabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    isMicEnabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    onUpdateCamOpen: {
      type: Function,
      required: false,
    },
    onUpdateMicOpen: {
      type: Function,
      required: false,
    },
    hideFooter: {
      type: Boolean,
      required: false,
    },
  },
  emits: ["update:visible"],
  components: {
    Button,
    Modal,
    Row,
    Space,
    CheckOutlined,
  },
  setup(props, { emit }) {
    const { getters, dispatch } = useStore();
    const { isTeacherSide } = useClientSide();
    const deviceSettingsCompRef = ref<InstanceType<typeof DeviceSettings>>();
    const currentIsCamEnabled = ref<Boolean>(props.isCamEnabled);
    const currentIsMicEnabled = ref<Boolean>(props.isMicEnabled);
    const isDeviceError = ref(false);
    const initialDeviceIds = reactive<{ cam?: string; mic?: string; speaker?: string }>({});
    const closeModal = () => {
      emit("update:visible", false);
    };

    const onOpenMicChange = (currentOpenMicValue: boolean) => {
      currentIsMicEnabled.value = currentOpenMicValue;
    };

    const onOpenCamChange = (currentOpenCamValue: boolean) => {
      currentIsCamEnabled.value = currentOpenCamValue;
    };

    const handleDeviceError = (isError: boolean) => {
      isDeviceError.value = isError;
    };

    const isOpenCamChange = computed(() => props.isCamEnabled !== currentIsCamEnabled.value);
    const isOpenMicChange = computed(() => props.isMicEnabled !== currentIsMicEnabled.value);

    const isCamChanged = computed(() => {
      if (!initialDeviceIds.cam && !deviceSettingsCompRef.value?.activeCameraId) {
        return false;
      }
      return initialDeviceIds.cam !== deviceSettingsCompRef.value?.activeCameraId;
    });
    const isMicChanged = computed(() => {
      if (!initialDeviceIds.mic && !deviceSettingsCompRef.value?.activeMicrophoneId) {
        return false;
      }
      return initialDeviceIds.mic !== deviceSettingsCompRef.value?.activeMicrophoneId;
    });
    const isSpeakerChanged = computed(() => {
      if (!initialDeviceIds.speaker && !deviceSettingsCompRef.value?.activeSpeakerId) {
        return false;
      }
      return initialDeviceIds.speaker !== deviceSettingsCompRef.value?.activeSpeakerId;
    });

    const isUpdateBtnDisabled = computed(() => {
      return (
        isDeviceError.value ||
        (!isOpenCamChange.value && !isOpenMicChange.value && !isCamChanged.value && !isSpeakerChanged.value && !isMicChanged.value)
      );
    });

    const onUpdateDeviceSettings = async () => {
      // Update new device ids
      await Promise.all([
        dispatch("setCameraDeviceId", deviceSettingsCompRef.value?.activeCameraId ?? ""),
        dispatch("setMicrophoneDeviceId", deviceSettingsCompRef.value?.activeMicrophoneId ?? ""),
        dispatch("setSpeakerDeviceId", deviceSettingsCompRef.value?.activeSpeakerId ?? ""),
      ]);

      // Update new device status (on/off)
      if (isOpenCamChange.value && props.onUpdateCamOpen) {
        await props.onUpdateCamOpen(currentIsCamEnabled.value);
      }
      if (isOpenMicChange.value && props.onUpdateMicOpen) {
        await props.onUpdateMicOpen(currentIsMicEnabled.value);
      }
      const module = isTeacherSide.value ? "teacherRoom" : "studentRoom";
      if (isCamChanged.value) {
        await dispatch(`${module}/updateCameraDevice`, {});
      }
      if (isMicChanged.value) {
        await dispatch(`${module}/updateMicrophoneDevice`, {});
      }
      if (isSpeakerChanged.value) {
        await dispatch(`${module}/updateSpeakerDevice`, {});
      }
      emit("update:visible", false);
    };

    const setInitialDeviceIds = () => {
      initialDeviceIds.cam = getters["cameraDeviceId"];
      initialDeviceIds.mic = getters["microphoneDeviceId"];
      initialDeviceIds.speaker = getters["speakerDeviceId"];
    };
    watch(
      () => props.visible,
      (val) => {
        if (val) {
          currentIsCamEnabled.value = props.isCamEnabled;
          currentIsMicEnabled.value = props.isMicEnabled;

          setInitialDeviceIds();
        }
      },
    );

    const DeviceSettingsText = computed(() => fmtMsg(ClassSetUp.DeviceSettings));
    const CommonUpdateText = computed(() => fmtMsg(CommonLocale.CommonUpdateButtonText));
    const CommonCancelText = computed(() => fmtMsg(CommonLocale.CommonCancelButtonText));

    return {
      closeModal,
      isUpdateBtnDisabled,
      onOpenMicChange,
      onOpenCamChange,
      handleDeviceError,
      onUpdateDeviceSettings,
      DeviceSettingsText,
      CommonCancelText,
      CommonUpdateText,
      deviceSettingsCompRef,
    };
  },
});
