<template>
  <Modal :visible="visible" @cancel="closeModal" :footer="null" :destroyOnClose="true" :width="900" :zIndex="998">
    <DeviceSettings
      ref="deviceSettingsCompRef"
      :title="DeviceSettingsText"
      :isCamEnabled="isCamEnabled"
      :isMicEnabled="isMicEnabled"
      :isMidSession="true"
      @onOpenCamChange="onOpenCamChange"
      @onOpenMicChange="onOpenMicChange"
      @onDeviceError="handleDeviceError"
    >
      <template #default v-if="!hideFooter">
        <div class="mt-5 text-right space-x-1">
          <button
            class="transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md cursor-pointer focus:ring-4 focus:ring-gs-purple focus:ring-opacity-20 focus-visible:outline-none [&amp;:hover:not(:disabled)]:bg-opacity-90 [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed border-secondary text-slate-500 [&amp;:hover:not(:disabled)]:bg-secondary/20 w-24"
            type="button"
            @click="closeModal"
          >
            {{ CommonCancelText }}
          </button>
          <button
            class="transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-5 rounded-md cursor-pointer focus:ring-4 focus:ring-gs-purple focus:ring-opacity-20 focus-visible:outline-none [&amp;:hover:not(:disabled)]:bg-opacity-90 [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-gs-purple border-gs-purple text-white"
            type="button"
            :disabled="isUpdateBtnDisabled"
            @click="onUpdateDeviceSettings"
          >
            <CheckOutlined style="padding-inline: 3px; font-size: 18px" />
            <span>{{ CommonUpdateText }}</span>
          </button>
        </div>
      </template>
    </DeviceSettings>
  </Modal>
</template>
<style lang="scss" scoped src="./device-settings-modal.scss"></style>
<script lang="ts" src="./device-settings-modal.ts"></script>
